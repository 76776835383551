.dx-field-label {
  color: black;
}

.dx-field-item-label-text {
  color: black;
}

.dx-placeholder {
  color: black;
}

div.thick {
  font-weight: bold;
}

.dx-tab-selected {
  font-weight: bold;
  background-color: white;
}

div.normal {
  font-weight: normal;
}

#compareSchedulesChart1 {
  height: 680px;
}

#shiny-iframe-container {
  max-width: 100%;
  border: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.kpi-resume-cell-center {
  margin-top: 0.1rem;
  margin-bottom: 0.1rem;
  margin-right: auto;
  margin-left: auto;
  align-items: center;
  justify-content: center;
  text-align: right;
}

.kpi-resume-cell-right {
  margin-top: 0.1rem;
  margin-bottom: 0.1rem;
  margin-right: 0rem;
  margin-left: auto;
}

.rt-tbody-dev {
  overflow: visible !important;
}
.resume-rankings-sticky-dev {
  position: sticky !important;
  left: 0;
  top: 0;
  z-index: 1;
  background-color: white;
  border-bottom: 1px solid #e9ecef;
}

.kpi-resume-upload input {
  outline: 2px dashed #92b0b3;
  outline-offset: -10px;
  -webkit-transition: outline-offset 0.15s ease-in-out, background-color 0.15s linear;
  transition: outline-offset 0.15s ease-in-out, background-color 0.15s linear;
  padding: 120px 0px 85px 35%;
  text-align: center !important;
  margin: 0;
  width: 100% !important;
}
.kpi-resume-upload input:focus {
  outline: 2px dashed #92b0b3;
  outline-offset: -10px;
  -webkit-transition: outline-offset 0.15s ease-in-out, background-color 0.15s linear;
  transition: outline-offset 0.15s ease-in-out, background-color 0.15s linear;
  border: 1px solid #92b0b3;
}
.kpi-resume-upload {
  position: relative;
}
.kpi-resume-upload:after {
  pointer-events: none;
  position: absolute;
  top: 60px;
  left: 0;
  width: 50px;
  right: 0;
  height: 56px;
  content: "";
  background-image: url(https://image.flaticon.com/icons/png/128/109/109612.png);
  display: block;
  margin: 0 auto;
  background-size: 100%;
  background-repeat: no-repeat;
}
.color input {
  background-color: #f1f1f1;
}
.kpi-resume-upload:before {
  position: absolute;
  bottom: 0px;
  left: 0;
  pointer-events: none;
  width: 100%;
  right: 0;
  height: 40px;
  content: " or drag it here. ";
  display: block;
  margin: 0 auto;
  color: #2ea591;
  font-weight: 600;
  text-transform: capitalize;
  text-align: center;
}
